import React, { useRef, useState } from 'react'
import Helmet from 'react-helmet'

import {
  PlayerWrapper,
  PlayerContainer,
  ControlsWrapper,
  PlayerInfoText,
} from './styled'
import { PlayerControls } from './controls'

export const Player = ({ source, info, isMobile, isVideo, variant }) => {
  const videoRef = useRef(null)
  const [playing, setPlaying] = useState(false)
  const [duration, setDuration] = useState(0)
  const [volume] = useState(0.8)
  const [muted, setMuted] = useState(false)
  const [progress, setProgress] = useState(0)

  const onTogglePlay = () => {
    setPlaying((value) => !value)
  }

  const onProgress = ({ played }) => {
    setProgress(played)
  }

  const onDuration = (value) => {
    setDuration(value)
  }

  const onSetProgress = (value) => {
    videoRef.current.seekTo(value)
  }

  const onClickFullscreen = () => {
    // screenfull.request(videoRef.current)
    const el = videoRef.current.getInternalPlayer()
    if (el.requestFullscreen) {
      el.requestFullscreen()
    } else if (el.mozRequestFullScreen) {
      /* Firefox */
      el.mozRequestFullScreen()
    } else if (el.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      el.webkitRequestFullscreen()
    } else if (el.msRequestFullscreen) {
      /* IE/Edge */
      el.msRequestFullscreen()
    }
  }

  return (
    <>
      <Helmet
        link={[
          {
            href: '/fonts.css',
            rel: 'stylesheet',
            type: 'text/css',
          },
        ]}
      />
      <PlayerContainer>
        <PlayerWrapper
          variant={variant}
          onClick={onTogglePlay}
          playing={playing}
          volume={volume}
          muted={muted}
          ref={videoRef}
          width="100%"
          height="100%"
          url={source}
          onProgress={onProgress}
          onDuration={onDuration}
          progressInterval={1000 / 60}
        />
        <ControlsWrapper variant={isVideo ? 'video' : 'audio'}>
          <PlayerInfoText>{info}</PlayerInfoText>
          <PlayerControls
            onPressPlay={() => setPlaying(!playing)}
            onPressMute={() => setMuted(!muted)}
            onPressFullscreen={onClickFullscreen}
            isMobile={isMobile}
            isPlaying={playing}
            isMuted={muted}
            isVideo={isVideo}
            progress={progress}
            duration={duration}
            onUpdateProgress={onSetProgress}
          />
        </ControlsWrapper>
      </PlayerContainer>
    </>
  )
}
