import React from 'react'
import { NewsArticleHeadline } from './headline'
import { Panels, Container } from './styled'

interface articleMedia {
  type: string
  info: string
  source: string
}

export const NewsArticle: React.FC<{
  pretitle: string
  title: string
  date: string
  html: string
  media?: articleMedia
  isMobile: boolean
}> = ({ pretitle, title, date, html, media, isMobile }) => (
  <Panels>
    <NewsArticleHeadline
      title={title}
      date={date}
      pretitle={pretitle}
      html={html}
      media={media}
      isMobile={isMobile}
    />
    {isMobile ? null : <Container padding={isMobile ? '0' : '0 0 0 120px'} />}
  </Panels>
)
