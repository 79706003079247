import React from 'react'
import { Player } from './player'
import {
  Container,
  Column,
  PreTitle,
  Title,
  Paragraph,
  HTMLArticle,
} from './styled'

export const NewsArticleHeadline: React.FC<{
  pretitle: string
  title: string
  date: string
  html: string
  media
  isMobile: boolean
}> = ({ pretitle, title, date, html, media, isMobile }) => {
  const isMediaAudio = media?.type.startsWith('audio')
  const isMediaVideo = media?.type.startsWith('video')

  let MediaPlayer = null
  if (isMediaAudio) {
    MediaPlayer = (
      <Player
        variant="audio"
        info={media?.info}
        source={media?.source}
        isMobile={isMobile}
      />
    )
  } else if (isMobile && isMediaVideo) {
    MediaPlayer = (
      <Player
        variant="video"
        source={media?.source}
        isMobile={isMobile}
        isVideo
      />
    )
  }
  return (
    <Container padding={isMobile ? '0' : '0 120px 0 0'}>
      <Column>
        <PreTitle>{pretitle}</PreTitle>
        <Title variant="headline">{title}</Title>
        <Paragraph>{date}</Paragraph>
        {MediaPlayer}
      </Column>
      <Column>
        <HTMLArticle dangerouslySetInnerHTML={{ __html: html }} />
      </Column>
    </Container>
  )
}
