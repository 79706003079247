import React, { useRef, useEffect } from 'react'
import styled, { Box, css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
  color: #ffffff;

  padding: 0 32px;

  span {
    font-family: title;
    font-size: 20px;
    text-transform: uppercase;
    line-height: 20px;
  }
`

const ProgressWrap = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  flex: 1;
  height: 100%;
`
const Progress = styled.svg`
  width: 100%;
  height: 1px;
`

const Knob = styled.svg`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: ${(props) => props.progress * 100}%;
  width: 19px;
  height: 19px;
`

const ButtonWrap = styled.div`
  ${breakpoints({
    md: css`
      transform: scale(0.8);
    `,
  })}

  cursor: pointer;

  :hover {
    rect,
    path {
      fill: #337f65;
    }
  }
`

const Muted = ({ onPress }) => (
  <ButtonWrap onClick={onPress}>
    <svg height="4" width="21" viewbox="0 0 21 4">
      <rect x="4" width="2" height="4" fill="#ffffff" />
      <rect x="9" width="2" height="4" fill="#ffffff" />
      <rect x="14" width="2" height="4" fill="#ffffff" />
      <rect x="19" width="2" height="4" fill="#ffffff" />
    </svg>
  </ButtonWrap>
)
const Unmuted = ({ onPress }) => (
  <ButtonWrap onClick={onPress}>
    <svg height="16" width="21" viewBox="0 0 21 16">
      <rect x="4" y="8" width="2" height="8" fill="#ffffff" />
      <rect x="9" y="4" width="2" height="12" fill="#ffffff" />
      <rect x="14" width="2" height="16" fill="#ffffff" />
      <rect x="19" y="8" width="2" height="8" fill="#ffffff" />
    </svg>
  </ButtonWrap>
)
const Play = ({ onPress }) => (
  <ButtonWrap onClick={onPress}>
    <svg height="20" width="20" viewBox="0 0 20 20">
      <path
        d="M127,654v20l20-10Z"
        transform="translate(-127 -654)"
        fill="#ffffff"
      />
    </svg>
  </ButtonWrap>
)
const Pause = ({ onPress }) => (
  <ButtonWrap onClick={onPress}>
    <svg height="20" width="20" viewBox="0 0 20 20">
      <rect width="6" height="19" fill="#ffffff" />
      <rect x="9" width="6" height="19" fill="#ffffff" />
    </svg>
  </ButtonWrap>
)
const Fullscreen = ({ onPress }) => (
  <ButtonWrap onClick={onPress}>
    <svg width="17" height="15" viewBox="0 0 21 19">
      <path
        d="M3436,1362h-4v15h4v2h-6v-19h6v2Z"
        transform="translate(-3430 -1360)"
        fill="#ffffff"
      />
      <path
        d="M3445,1362h4v15h-4v2h6v-19h-6v2Z"
        transform="translate(-3430 -1360)"
        fill="#ffffff"
      />
    </svg>
  </ButtonWrap>
)

const secondsToText = (value) => {
  const minutes = Math.floor(value / 60)
    .toString()
    .padStart(2, '0')

  const seconds = Math.floor(value % 60)
    .toString()
    .padStart(2, '0')

  return [minutes, seconds].join(':')
}

export function PlayerControls({
  progress = 0.4,
  duration = 0,
  onUpdateProgress,
  onPressPlay,
  onPressMute,
  onPressFullscreen,
  isMobile,
  isPlaying,
  isMuted,
  isFullscreen,
  isVideo,
}) {
  const progressBarRef = useRef(null)
  const knobRef = useRef(null)
  function onSetProgress(event) {
    const bounds = progressBarRef.current.getBoundingClientRect()
    const value = (event.clientX - bounds.left) / bounds.width

    onUpdateProgress(value)
  }

  useEffect(() => {
    if (knobRef.current) {
      knobRef.current.style.left = `${progress * 100}%`
    }
  }, [progress])

  return isMobile ? (
    <Container>
      <Box pr="22px" height="100%" display="flex" alignItems="center">
        {isPlaying && progress < 1 ? (
          <Pause onPress={onPressPlay} />
        ) : (
          <Play onPress={onPressPlay} />
        )}
      </Box>
      <ProgressWrap
        ref={progressBarRef}
        flex="1"
        position="relative"
        onClick={onSetProgress}
      >
        <Progress viewBox="0 0 100 2" preserveAspectRatio="none">
          <path
            d="M0 0 L100 0"
            strokeWidth="2"
            stroke="#ffffff"
            strokeOpacity={0.4}
          />
          <g className="progress-line" transform={`scale(${progress} 1)`}>
            <path d="M0 0 L100 0" strokeWidth="2" stroke="#ffffff" />
          </g>
        </Progress>
        <Knob viewBox="0 0 38 38" ref={knobRef}>
          <circle r="8" cx="19" cy="19" fill="#737373" />
        </Knob>
      </ProgressWrap>
      <Box pl="22px" height="100%" display="flex" alignItems="center">
        {isMuted ? (
          <Muted onPress={onPressMute} />
        ) : (
          <Unmuted onPress={onPressMute} />
        )}
      </Box>
      <Box pl="22px" height="100%" display="flex" alignItems="center">
        {isPlaying || progress !== 0 ? (
          <span>{secondsToText(duration * progress)}</span>
        ) : (
          <span>{secondsToText(duration)}</span>
        )}
      </Box>
      {isVideo ? (
        <Box pl="22px" height="100%" display="flex" alignItems="center">
          <Fullscreen onPress={onPressFullscreen} />
        </Box>
      ) : null}
    </Container>
  ) : (
    <Container>
      <Box pr="22px" height="100%" display="flex" alignItems="center">
        {isPlaying ? (
          <Pause onPress={onPressPlay} />
        ) : (
          <Play onPress={onPressPlay} />
        )}
      </Box>
      <Box pr="22px" height="100%" display="flex" alignItems="center">
        <span>{secondsToText(duration * progress)}</span>
      </Box>
      <ProgressWrap
        ref={progressBarRef}
        flex="1"
        position="relative"
        onClick={onSetProgress}
      >
        <Progress viewBox="0 0 100 2" preserveAspectRatio="none">
          <path
            d="M0 0 L100 0"
            strokeWidth="2"
            stroke="#ffffff"
            strokeOpacity={0.4}
          />
          <g className="progress-line" transform={`scale(${progress} 1)`}>
            <path d="M0 0 L100 0" strokeWidth="2" stroke="#ffffff" />
          </g>
        </Progress>
        <Knob viewBox="0 0 38 38" ref={knobRef}>
          <circle
            r="18"
            cx="19"
            cy="19"
            stroke="#ffffff"
            fill="transparent"
            strokeWidth="2"
          />
          <circle r="8" cx="19" cy="19" fill="#337f65" />
        </Knob>
      </ProgressWrap>
      <Box pl="22px" height="100%" display="flex" alignItems="center">
        <span>{secondsToText(duration)}</span>
      </Box>
      <Box pl="22px" height="100%" display="flex" alignItems="center">
        {isMuted ? (
          <Muted onPress={onPressMute} />
        ) : (
          <Unmuted onPress={onPressMute} />
        )}
      </Box>
      {isVideo ? (
        <Box pl="22px" height="100%" display="flex" alignItems="center">
          <Fullscreen onPress={onPressFullscreen} />
        </Box>
      ) : null}
    </Container>
  )
}
