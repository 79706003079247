export const convertVideoUrl = (source) => {
    let file = source.substring(source.lastIndexOf("/")+1);
    let newpath = "https://ohpen-cms-converted.s3-eu-west-1.amazonaws.com/videos/"+file + ".mp4";
    return newpath;
}

export const convertVideoUrlThumbnail = (source) => {
    let file = source.substring(source.lastIndexOf("/")+1);
    let newpath = "https://ohpen-cms-converted.s3-eu-west-1.amazonaws.com/videos/"+file + "-00001.jpg";
    return newpath;
}
