import React, { useEffect, useRef } from 'react'
import { BlurredSlide, Slide, Slides, VideoSlide } from './styled'
import { Player } from './player'
import {
  convertVideoUrl,
  convertVideoUrlThumbnail,
} from '@/modules/utils/convertVideoUrl'
import gsap from 'gsap'

const MapBlurredSlides = (slide) => (
  <BlurredSlide key={slide} backgroundImage={slide} />
)

const MapSlides = (slide) => <Slide key={slide} backgroundImage={slide} />

export const SlideShow = ({ slides, isMobile }) => {
  const slidesContainer = useRef(null)
  const tl = gsap.timeline({ repeat: -1 })
  useEffect(() => {
    if (!isMobile) {
      const nodes = slidesContainer.current?.childNodes || []
      const timeToPause = 5
      const duration = 1
      nodes.forEach((node, index) => {
        if (index === 0) {
          return
        }
        const isLastIndex = index === nodes.length - 1
        tl.fromTo(
          node,
          { autoAlpha: 0 },
          { autoAlpha: 1, duration },
          index * timeToPause,
        )
        if (isLastIndex) {
          tl.to(node, { autoAlpha: 0, duration }, (index + 1) * timeToPause)
        } else {
          tl.set(node, { autoAlpha: 0 }, (index + 1) * timeToPause + duration)
        }
      })
    }
    return () => {
      if (tl) {
        tl.kill()
      }
    }
  }, [isMobile])

  return isMobile ? null : (
    <Slides ref={slidesContainer}>{(slides || []).map(MapSlides)}</Slides>
  )
}

export const VideoSlideShow = ({ slides, media }) => {
  let poster = (slides || []).map(MapBlurredSlides).shift()
  if (media) {
    poster = MapBlurredSlides(convertVideoUrlThumbnail(media?.source))
  }
  return (
    <Slides>
      {poster}
      <VideoSlide>
        <Player
          variant="video"
          source={convertVideoUrl(media?.source)}
          isVideo
        />
      </VideoSlide>
    </Slides>
  )
}
