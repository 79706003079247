import React, { useEffect, useState } from 'react'
import Page from '@/components/Page'
import {
  NewsArticle,
  SlideShow,
  VideoSlideShow,
} from '@/components/NewsArticle'
import {
  Background,
  SlideTitle,
  SlidesText,
} from '@/components/NewsArticle/styled'
import { pageFadeInOut } from '@/modules/animationVariants'
import { Left, MobileFooter } from '@/layouts/styled'
import { SideNavigationLink } from '@/components/SideNavigationLink'
import gsap from 'gsap'
import { graphql } from 'gatsby'
import format from 'date-fns/format'
import MobileBack from '@/components/MobileBack'
import {
  APP_SWITCH_BACKGROUND_THREE_SCENE,
  APP_PAGE_ANIMATION_COMPLETED,
} from '@/events'
import PubSub from 'pubsub-js'
import { SimplifiedPageWrapper } from '@/components/PageWrapper/SimplifiedPageWrapper'
import { Text } from '@/components/Text'

const isServer = typeof window === 'undefined'
const getIsMobile = () =>
  isServer ? false : document.documentElement.clientWidth <= 992

export const query = graphql`
  query cmsNews($id: String) {
    cmsNews(id: { eq: $id }) {
      id
      slug
      type
      title
      excerpt
      body
      desktop_body
      date
      city
      media {
        ... on CmsMedia {
          id
          type
          data {
            full_url
            url
            asset_url
          }
        }
      }
      thumbnail {
        data {
          url
        }
      }
      desktop_images {
        id
        type
        title
        data {
          url
        }
      }
    }
  }
`

const createSlides = (node) =>
  node.desktop_images.map((image) => image.data.url)

const createMediaObject = (node) => {
  if (node.media) {
    return {
      type: node.media.type,
      info: node.media.title,
      source: node.media.data.url,
    }
  }

  return null
}

const formatNewsDate = (node) => format(new Date(node.date), 'MMMM do, yyyy')

const transformNode = (node) => ({
  id: node.id,
  subheadline: 'Article',
  headline: node.title,
  date: node.date ? [node.city, formatNewsDate(node)].join(', ') : node.city,
  desktopRawHTML: node.desktop_body,
  mobileRawHTML: node.body,
  slides: createSlides(node),
  media: createMediaObject(node),
})

interface NewsArticleData {
  cmsNews: GatsbyTypes.CmsNews
}

const Index: React.FC<{ data: NewsArticleData }> = ({ data }) => {
  const [isMobile, setIsMobile] = useState(getIsMobile())
  const pageRef = React.useRef(null)

  useEffect(() => {
    PubSub.publish(APP_SWITCH_BACKGROUND_THREE_SCENE, {
      sceneName: 'defaultDark',
    })
    PubSub.publish(APP_PAGE_ANIMATION_COMPLETED)
    const onResize = () => setIsMobile(getIsMobile())
    window.addEventListener('resize', onResize)

    gsap
      .fromTo(
        pageRef.current,
        { autoAlpha: 0 },
        { autoAlpha: 1, duration: 2, ease: 'Circ.easeOut' },
      )
      .delay(2)

    return () => window.removeEventListener('resize', onResize)
  }, [])

  const {
    subheadline,
    headline,
    date,
    desktopRawHTML,
    mobileRawHTML,
    slides,
    media,
  } = transformNode(data.cmsNews)

  const isMediaVideo = media?.type === 'video/mp4'

  return (
    <>
      <Left>
        <SideNavigationLink
          variant="light"
          position="left"
          delay={0}
          time={1}
          url="/latest-insights/overview"
          text="back"
        />
      </Left>
      <Page
        content={
          <div ref={pageRef}>
            <Background />
            <SimplifiedPageWrapper>
              <NewsArticle
                isMobile={isMobile}
                pretitle={subheadline}
                title={headline}
                date={date}
                html={isMobile ? mobileRawHTML : desktopRawHTML}
                media={media}
              />
            </SimplifiedPageWrapper>
            {isMediaVideo && !isMobile && slides && (
              <VideoSlideShow media={media} slides={slides} />
            )}
            {!isMediaVideo && slides && (
              <SlideShow slides={slides} isMobile={isMobile} />
            )}
            {!isMediaVideo && slides.length < 1 && (
              <SlidesText>
                <SlideTitle>
                  <Text title={headline} />
                </SlideTitle>
              </SlidesText>
            )}
          </div>
        }
        settings={{
          backgroundColor: '#ffffff',
          contentAnimationVariants: pageFadeInOut(),
          useCircularWipe: false,
        }}
      />
      <MobileFooter id="mobile-footer">
        <MobileBack
          alwaysShow
          variant="light"
          delay={0}
          time={1}
          url="/latest-insights/overview"
          text="back"
        />
      </MobileFooter>
    </>
  )
}

export default Index
